// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * This is the new reference from the latest Figma design
 * Please find the color in figma, search if it isn't already 
 * here, if so use it, if not add it with clear name
 */
:export {
  breakpoint-mobile: 30rem;
  mobile: 30rem;
}

section#payments {
  align-items: center;
  background-color: #0f131a;
  border-radius: 0.5rem;
  color: #dfe0e2;
  display: flex;
  flex-direction: column;
  height: auto;
  margin: auto;
  padding: 3rem;
  margin: 0rem;
  max-width: min(80rem, 100vw - 20rem);
  overflow-y: auto;
  padding: 3rem;
  width: 100%;
}
section#payments header-title-payments {
  width: 100%;
}
section#payments div.from, section#payments div.to {
  max-width: 550px;
  width: 100%;
}
section#payments div.from div.texte {
  display: flex;
  flex: 2;
  flex-direction: row;
  text-overflow: ellipsis;
  overflow: hidden;
  line-break: anywhere;
  word-break: break-word;
  white-space: nowrap;
}
section#payments div.from_change, section#payments div.to_change {
  justify-content: space-between;
}
section#payments div.from_change a, section#payments div.to_change a {
  color: #0681b1;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  text-decoration: none;
}
section#payments h1 {
  font-size: 2.2rem;
  font-weight: 500;
  line-height: 2.2rem;
  margin: 1.1rem 0px 2.1rem 0;
  padding: 0 0rem;
  text-align: center;
  width: 100%;
}
section#payments h2 {
  font-size: 1.4rem !important;
  font-weight: 500;
  font-variant: all-small-caps;
}
section#payments div.listing div.object {
  border-radius: 0.5rem;
  height: auto !important;
  padding: 1rem !important;
}
section#payments div.listing div.object img {
  border-radius: 50%;
  height: 4rem !important;
  width: 4rem !important;
}
section#payments div.listing div.object p.title {
  font-size: 1rem;
}
section#payments div.listing div.object p.solde {
  font-size: 0.8rem;
  margin-bottom: 0;
}
section#payments div.payment banka-contact section {
  align-items: center;
  background-color: #1B2027 !important;
  height: 5.5rem;
}
section#payments div.amount_comment {
  text-align: center;
}
section#payments div.amount_comment p.amount {
  font-size: 3rem;
  font-weight: 500;
}
section#payments div.amount_comment p.comment {
  color: #999ca1;
  font-size: 2rem;
  font-weight: 400;
}
section#payments currency-formatter {
  height: 3rem;
  margin-bottom: 1rem;
  width: 100%;
}
section#payments currency-formatter:hover {
  border: 1px solid #232832;
  border-radius: 1rem;
}
section#payments input#comment {
  border-radius: 0.5rem;
}
section#payments input#comment:hover {
  border: 1px solid #232832;
  border-radius: 0.5rem;
}
section#payments form div.object {
  align-items: center;
  background-color: #0f131a !important;
  border-radius: 0.5rem;
  cursor: pointer;
  height: 5.5rem;
  margin: 0rem 0 1rem 0;
  max-width: 550px;
  justify-content: space-between;
  text-align: center;
  width: 100%;
  transition: border 0.25s ease-in;
}
section#payments form div {
  background-color: #0f131a !important;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
section#payments form div input#comment:focus {
  outline: none;
  border: 1px solid #dfe0e2;
  border-radius: 0.5rem;
}
section#payments form div p.currency {
  margin-bottom: 0;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 0 0.5rem 0.5rem 0;
  width: 5rem;
  text-align: center;
}
section#payments form div:focus {
  border: 1px solid #dfe0e2;
}
section#payments span.error {
  color: #EB5160;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 0.5rem;
  text-align: center;
}

.no_border {
  border: 0 !important;
}

@media only screen and (min-width: 55rem) and (max-width: 65rem) {
  section#payments {
    max-width: min(80rem, 100vw - 5.5rem);
    padding: 1rem !important;
  }
}
@media only screen and (min-width: 40rem) and (max-width: 55rem) {
  section#payments {
    margin: 0;
    max-width: 100%;
    padding: 1rem;
  }
}
@media only screen and (max-width: 40rem) {
  section#payments {
    margin-bottom: 4rem;
    max-width: 100%;
    padding: 0 1rem;
  }
  section#payments header-title-payments {
    padding: 1rem 0 0 0;
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/colors.scss","webpack://./src/breakpoints.scss","webpack://./src/pages/payments/payments.scss"],"names":[],"mappings":"AAAA;;;;EAAA;AC+CA;EAEQ,wBAxBa;EA2BjB,aA3BiB;ACjBrB;;AALA;EACE,mBAAA;EACA,yBF4CuB;EE3CvB,qBAAA;EACA,cF4CkB;EE3ClB,aAAA;EACA,sBAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,YAAA;EACA,oCAAA;EACA,gBAAA;EACA,aAAA;EACA,WAAA;AAQF;AANE;EACE,WAAA;AAQJ;AALE;EACE,gBAAA;EACA,WAAA;AAOJ;AADI;EACE,aAAA;EACA,OAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;EACA,oBAAA;EACA,sBAAA;EACA,mBAAA;AAGN;AAEE;EACE,8BAAA;AAAJ;AAEI;EACE,cFXgB;EEYhB,eAAA;EACA,eAAA;EACA,gBAAA;EACA,qBAAA;AAAN;AAIE;EACE,iBAAA;EACA,gBAAA;EACA,mBAAA;EACA,2BAAA;EACA,eAAA;EACA,kBAAA;EACA,WAAA;AAFJ;AAKE;EACE,4BAAA;EACA,gBAAA;EACA,4BAAA;AAHJ;AAQI;EACE,qBAAA;EACA,uBAAA;EACA,wBAAA;AANN;AAQM;EACE,kBAAA;EACA,uBAAA;EACA,sBAAA;AANR;AAQM;EACE,eAAA;AANR;AAQM;EACE,iBAAA;EACA,gBAAA;AANR;AAeM;EACE,mBAAA;EACA,oCAAA;EACA,cAAA;AAbR;AAkBE;EACE,kBAAA;AAhBJ;AAkBI;EACE,eAAA;EACA,gBAAA;AAhBN;AAmBI;EACE,cF/Da;EEgEb,eAAA;EACA,gBAAA;AAjBN;AAoBE;EACE,YAAA;EACA,mBAAA;EACA,WAAA;AAlBJ;AAoBI;EACE,yBAAA;EACA,mBAAA;AAlBN;AAsBE;EACE,qBAAA;AApBJ;AAuBE;EACI,yBAAA;EACA,qBAAA;AArBN;AAyBI;EACE,mBAAA;EACA,oCAAA;EACA,qBAAA;EACA,eAAA;EACA,cAAA;EACA,qBAAA;EACA,gBAAA;EACA,8BAAA;EACA,kBAAA;EACA,WAAA;EACA,gCAAA;AAvBN;AA0BI;EACE,oCAAA;EACA,0CAAA;AAxBN;AA0BM;EACE,aAAA;EACA,yBAAA;EACA,qBAAA;AAxBR;AA2BM;EACE,gBAAA;EACA,aAAA;EACA,0CF7HuB;EE8HvB,gCAAA;EACA,WAAA;EACA,kBAAA;AAzBR;AA4BI;EACE,yBAAA;AA1BN;AA8BE;EACE,cFtHc;EEuHd,iBAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;AA5BJ;;AAgCA;EACE,oBAAA;AA7BF;;AAiCA;EACE;IACE,qCAAA;IACA,wBAAA;EA9BF;AACF;AAkCA;EACE;IACE,SAAA;IACA,eAAA;IACA,aAAA;EAhCF;AACF;AAoCA;EACE;IACE,mBAAA;IACA,eAAA;IACA,eAAA;EAlCF;EAoCE;IACE,mBAAA;IACA,WAAA;EAlCJ;AACF","sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
